/* eslint-disable semi */
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useMemo } from 'react';
import './Home.css';
import CobblestoneSVG from './cobblestone.svg';
import GrassSVG from './grass.svg';
import TeapotSVG from './teapot.svg';
import InstaSVG from './instagram.svg'
import TheZuckSVG from './TheZuck.svg';
import BirdAppSVG from './BirdApp.svg';
import ClockAppSVG from './ClockApp.svg';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from './App'

function Home(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true)
  const [hamburger, setHamburger] = React.useState(false)
  const [state, dispatch] = React.useContext(UserContext)
  const hamburgerClicked = useCallback(
    async (path) => {
    setHamburger(false)
    setTimeout(() => {
      navigate(path)
    }, 40)
    },
    [navigate, setHamburger]
  )
  setTimeout(() => setIsLoading(false), 0);
  if (isLoading) {
    return (
      <div>
        <img src={CobblestoneSVG} style={{ display: 'none' }} />
        <img src={GrassSVG} style={{ display: 'none' }} />
        <img src={TeapotSVG} style={{ display: 'none' }} />

      </div>
    );
  }
  return (
    <div className="container" onClick={() => setHamburger(false)}>
      <img src={TeapotSVG} className="teapot-svg" style={{cursor: 'pointer'}} onClick={() => navigate('')}/>
      <div className="hamburger" onClick={(e) => {
        setHamburger(!hamburger)
        e.stopPropagation()
      }}/>
      <div className="hamburger-menu" style={{display: `${hamburger ? '' : 'none'}`}}>
        <div className="hamburger-menu-item" onClick={() => hamburgerClicked('/about')}>About</div>
        <div className="hamburger-menu-item" onClick={() => hamburgerClicked('/contact')}>Contact</div>
        <div className="hamburger-menu-item" onClick={() => hamburgerClicked('/hiring')}>Hiring</div>
        <div className="hamburger-menu-item" onClick={() => window.open('https://thebubblingteapot.square.site/events', '_blank')}>Events</div>
        <div className="hamburger-menu-item" onClick={() => hamburgerClicked('/login')}>{state.user_obj ? 'My Account' : 'Login'}</div>
      </div>
      <div className="hamburger-line1" onClick={(e) => {
        setHamburger(!hamburger)
        e.stopPropagation()
      }}
        />
      <div className="hamburger-line2" onClick={(e) => {
        setHamburger(!hamburger)
        e.stopPropagation()
      }}/>
      <div className="hamburger-line3" onClick={(e) => {
        setHamburger(!hamburger)
        e.stopPropagation()
      }}/>
      <div className='cobble' style={{
        backgroundImage: `url(${CobblestoneSVG})`,
        backgroundRepeat: 'repeat-x repeat-y',
        backgroundColor: '#9D8FCC',
        textAlign: 'center',
        color: '#201002',
        textShadow: '0px 0.52vh 0.422vh rgba(0, 0, 0, 0.25)',
        fontFamily: 'IM Fell English SC, serif',
        zIndex: 1,
        width: '100%',
      }}>
      <div
        style={{
          display: 'flex',
          width: 'calc(100% - 8vh)',
          height: '100%',
        }}
      >
        <div style={{flex: 1, paddingTop: '0.5em', paddingBottom: '0.5em', paddingLeft: '0.5em'}}>
        <img src={TeapotSVG} className="teapot-svg-mobile" style={{cursor: 'pointer'}} onClick={() => navigate('')}/>
        </div>
        <div className="title-text" style={{cursor: 'pointer'}} onClick={() => navigate('')}>
          The Bubbling
          <br />
          Teapot
        </div>
        <div className="title-text-mobile">
          The Bubbling <br /> Teapot
        </div>
        <div className="info-text" >
          <span style={{ fontSize: '4.5vh' }}> Hours</span>
          {' '}
          <br />
          {' '}
          Tuesday - Saturday
          {' '}
          <br />
          {' '}
          Noon - 6pm
          {' '}
          <br />
          {' '}
          <br />
          {' '}
          2611 East Johnson St
          {' '}
          <br />
          {' '}
          Madison, WI 53704
        </div>
      </div>
      </div>
      <div className="nav-bar">
        <div className="nav-bar-item" onClick={() => navigate('/about')}>
          About
        </div>
        <div className="nav-bar-item" onClick={() => navigate('/contact')}>
          Contact
        </div>
        <div className="nav-bar-item" onClick={() => navigate('/hiring')}>
          Hiring
        </div>
        <div className="nav-bar-item" onClick={() => window.open('https://thebubblingteapot.square.site/events', '_blank')}>
          Events
        </div>
        <div onClick={() => navigate('/login')} className="nav-bar-item">
          {state.user_obj ? 'My Account' : 'Login'}
        </div>
      </div>
      <div className="nav-bar-mobile-wrapper">
      <div className="nav-bar-mobile">
        <div className="nav-bar-item-mobile">
          <a href="https://www.tiktok.com/@thebubblingteapot" target="_blank" rel='noreferrer'><img src={ClockAppSVG} style={{width: '4vh', height: '4vh'}}/></a>
        </div>
        <div className="nav-bar-item-mobile">
        <a href="https://www.twitter.com/bubblingteaWI" target="_blank" rel='noreferrer'><img src={BirdAppSVG} style={{width: '4vh', height: '4vh'}}/></a>
        </div>
        <div className="nav-bar-item-mobile">
          <a href="https://www.instagram.com/thebubblingteapot" target="_blank" rel='noreferrer'><img src={InstaSVG} style={{width: '4vh', height: '4vh'}}/></a>
        </div>
        <div className="nav-bar-item-mobile">
          <a href="https://www.facebook.com/The-Bubbling-Teapot-109071808519120" target="_blank" rel='noreferrer'><img src={TheZuckSVG} style={{width: '4vh', height: '4vh'}}/></a>
        </div>
      </div>
      </div>
      <div className="home-container" style={{
        backgroundColor: '#698550',
        fontFamily: 'Mina, sans-serif',
        textShadow: '0px 0.317vh 0.422vh rgba(0, 0, 0, 0.25)',
        backgroundSize: '90vh 90vh',
        flex: 1,
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        backgroundImage: `url(${GrassSVG})`,
        backgroundRepeat: 'repeat-x repeat-y',
        color: '#fffeff',
      }}
      >
        {props.children}
      </div>
    </div>
  );
}
export default Home;
